.product-benefit {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: -0.5208333333333333vw; //-10px;
  // height: 30.9375vw; //594px;

  .row {
    display: flex;
    align-items: center;
  }
  .header {
    width: 100%;
    margin-bottom: 1.8229166666666667vw; //35px;
    .head-title {
      font-size: 4.166666666666666vw; //80px;
      margin-bottom: -0.5208333333333333vw; //-10px;
      word-spacing: 0.5vw;
    }
  }
  .section1 {
    display: flex;
    // justify-content: center;
    align-items: center;
    .video-product-benefit {
      width: 42.44791666666667vw; //815px;
      height: 23.854166666666668vw; //458px;
      margin-top: -2.604166666666667vw; //-25px;
    }
    .content-benefit {
      margin-left: 2.083333333333333vw; //40px;
      .product-benefit-hover {
        // width: 26.822916666666668vw; //515px
        display: flex;
        // flex-direction: column;
        //   justify-content: center;
        // align-items: center;
        // height: 28.645833333333332vw; //550px;
        margin-bottom: 1.04vw; //20px;
        min-height: 7.291666666666667vw; //140px;
        // height: 3.6458333333333335vw; // 140px
        .img-icon {
          min-width: 5.15625vw; //99px;
          width: 6.25vw; //120px;
          height: 6.25vw; //99px;
          margin-right: 0.7291666666666666vw; //14px;
        }
        .content-hover {
          // width: 21.614583333333336vw; //415px;
          // display: none;
          opacity: 0;
          .head-title {
            font-size: 1.7708333333333333vw; //34px;
            margin-bottom: 0.5208333333333333vw; //10px;
          }
          .description {
            // text-align: center;
            // font-size: 0.5729166666666666vw; //11px;
            font-size: 0.8333333333333334vw; //16px;
            &.cleaning_efficacy {
              // width: 27.75vw;
            }
            &.reactive_skin {
              width: 34vw;
              @media screen and (max-width: 700px) {
                width: 35vw;
              }
              @media screen and (max-width: 554px) {
                width: 35.5vw;
              }
            }
            // &.harsh_free_and_gentle {
            // }
            &.moisture_balance {
              // width: 32vw;
            }
          }
        }

        &:hover {
          .content-hover {
            // display: block;
            opacity: 1;
          }
        }
        @media screen and (max-width: 700px) {
          // width: 22vw; //324px
          .description {
            font-size: 1vw;
          }
        }
      }
    }
  }
  .ml38 {
    margin-left: 1.9791666666666665vw; //38px;
  }
  .section2 {
    display: flex;
    justify-content: center;
    // margin-top: 0.5208333333333333vw; //10px;

    .button {
      width: 13.020833333333334vw; //250px;
      height: 2.083333333333333vw; //40px;
      border: 0.052083333333333336vw solid white; //1px
      justify-content: space-between;
      padding-left: 0.625vw; //12px;
      padding-right: 0.625vw; //12px;

      .button-title {
        font-size: 0.8333333333333334vw; //16px;
      }
      .img-icon {
        width: 0.9895833333333333vw; //19px;
        height: 1.1458333333333333vw; //22px;
        margin-right: 0.55vw;
      }
    }
  }
}
