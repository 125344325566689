.zoom-container {
	position: relative;
	&.zoom-in-animation {
		animation: zoom-in 1.5s ease-in;
	}
	&.zoom-out-animation {
		animation: zoom-out 1.5s ease-in;
	}
	&.fade-out-animation {
		animation: fade-out 1s ease-in;
	}
	&.hidden {
		display: none;
	}

	.tilt-phone-popup {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 0 20px;
		.tilt-phone-text {
			text-align: center;
		}
	}
}
@keyframes zoom-in {
	from {
		transform: scale(1);
		opacity: 1;
	}
	to {
		transform: scale(4);
		opacity: 0.4;
	}
}
@keyframes zoom-out {
	from {
		transform: scale(1);
		opacity: 1;
	}
	to {
		transform: scale(0.25);
		opacity: 0.4;
	}
}
@keyframes fade-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0.2;
	}
}
