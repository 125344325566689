.landing-popup {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .row {
    display: flex;
  }
  .mb53 {
    margin-bottom: 2.7604166666666665vw; //53px;
  }
  .ml33 {
    margin-left: 33px;
  }

  .text-center {
    text-align: center;
  }

  .button {
    width: 28.125vw; //540px;
    height: 7.8125vw; //150px;
    border: 1px solid white; //1px
    display: flex;
    padding-left: 2.34375vw; //45px;
    padding-right: 2.34375vw; //45px;
    justify-content: space-between;
    .img-icon {
      width: 3.6458333333333335vw; //70px;
      height: 4.53125vw; //87px;
      margin-right: 2.34375vw; ///45px;
    }
    .button-title {
      width: 100%;
      font-size: 1.7708333333333333vw; //34px;

      span {
        font-style: italic;
      }
      sup {
        font-size: 1.1vw;
      }
    }
    &.the_start_of_neutrogena {
      img {
        width: 4.6875vw; //90px;
      }
    }
    &.understanding_reactive_skin {
      img {
        width: 5.052083333333333vw; //97px;
      }
    }
  }
}
