.sales-zone {
  img.icon {
    position: absolute;
    user-select: none;
  }
  .blue-magnet-on-click {
    position: absolute;
    top: 30.989583333333332vw; //595px;
    right: 34.11458333333333vw; //655px;
    width: 6.510416666666667vw; //125px;
    height: 8.072916666666668vw;
    z-index: 3;
    cursor: pointer;
  }
  .blue-magnet-on {
    top: 26.5625vw; //510px;
    right: 29.583333333333332vw; //568px;
    width: 14.791666666666666vw; //284px;
    animation: twinkling 0.25s infinite ease-in-out;
    z-index: 2;
  }
  .blue-magnet-off {
    top: 26.5625vw; //510px;
    right: 29.583333333333332vw; //568px;
    width: 14.791666666666666vw; //284px;
    z-index: 1;
  }
  .orange-magnet-on-click {
    position: absolute;
    top: 30.989583333333332vw; //595px;
    left: 36.97916666666667vw; //710px;
    width: 6.510416666666667vw; //125px;
    height: 8.072916666666668vw;
    z-index: 3;
    cursor: pointer;
  }
  .orange-magnet-on {
    top: 26.5625vw; //510px;
    left: 32.8125vw; //630px;
    width: 14.791666666666666vw; //284px;
    animation: twinkling 0.25s infinite ease-in-out;
    z-index: 2;
  }
  .orange-magnet-off {
    top: 26.5625vw; //510px;
    left: 32.8125vw; //630px;
    width: 14.791666666666666vw; //284px;
    z-index: 1;
  }

  .button1 {
    left: 42.552083333333336vw; //817px
    top: 20.989583333333332vw; //403px
  }
  .button2 {
    left: 55.885416666666664vw; //1073px
    top: 17.604166666666668vw; //338px
  }
  .trophy-button-overlay {
    position: absolute;
    top: 22.58vw;
    left: 57.9vw;
    // background-color: rgba($color: red, $alpha: 0.5);
    width: 5.677083333333333vw; //109px;
    height: 11.354166666666666vw; //218px;
    cursor: pointer;
  }
  .glow-button-group {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 67.55208333333333vw; //1297px
    top: 19.53125vw; //375px
    .glow-button {
      width: 15.729166666666666vw; //302px
      height: 4.583333333333333vw; //88px
    }
  }
  .text-center {
    text-align: center;
  }

  .mr18 {
    margin-right: 0.9375vw; //18px;
  }

  .direction-group {
    position: absolute;
    top: auto;
    left: 48.17708333333333vw; //925px;
    bottom: 1.6145833333333335vw; //31px;
  }
  .click-here-left {
    position: absolute;
    top: 23.802083333333332vw; //457px;
    left: 48.69791666666667vw; //935px;
    background-image: url('../../images/click_here_gray_box_left.png');
    .bold-text {
      color: #acacac;
    }
  }
  .click-here-right {
    position: absolute;
    top: 19.114583333333332vw; //367px;
    left: 49.375vw; //948px;
    background-image: url('../../images/click_here_gray_box_right.png');
    .bold-text {
      color: #acacac;
    }
  }
  .button {
    width: 13.020833333333334vw; //250px;
    height: 2.083333333333333vw; //40px;
    border: 0.052083333333333336vw solid white; //1px
    .button-title {
      font-size: 0.8333333333333334vw; //16px;
    }
  }
  .product-info-wrapper {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .img-border {
      border: 0.052083333333333336vw solid white;
      border-radius: 0.10416666666666667vw; //2px
    }
    .product-info-content {
      display: flex;
      .main-button {
        width: 15.625vw;
        display: flex;
        align-items: center;
        padding: 0.55vw 0.55vw;
        border: 0.052083333333333336vw solid white;
        @media screen and (max-width: 920px) {
          border: 0.052083333333333336vw solid white;
        }
        cursor: pointer;
      }
      .left-content {
        margin-left: 2.3958333333333335vw; //46px;
        width: 25vw; //480px;

        // .large-img {
        //   width: 25vw; //480px;
        //   height: 31.197916666666664vw; //599px;
        //   margin-bottom: 1.3541666666666667vw; //26px;
        // }
        // .mini-img-group {
        //   display: flex;

        //   .mini-img {
        //     width: 3.28125vw; //63px;
        //     height: 4.114583333333333vw; //79px;
        //   }
        // }
        .slide-show {
          margin-top: 1.3541666666666667vw; //26px;
          overflow: hidden;

          .ss-thumbnail {
            max-width: 24.947916666666668vw; //25vw;
            width: 3.28125vw; //63px;
            height: 4.114583333333333vw; //79px;
            margin-right: 1.0416666666666665vw; //20px;
            border: 1px solid white;
            padding: 0px;
            overflow: hidden;
            &:last-child {
              margin-right: 0px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }

          .ss-thumbnails-track {
            width: 25vw;
          }
        }
        .slide-show .ss-fixed-height {
          padding: 0.5px;
          border: 1px solid white;
        }
        .slide-show .ss-thumbnail > img.ss-active {
          outline: none;
        }
      }
      .right-content {
        margin-left: 2.9166666666666665vw; //56px;
        margin-right: 0.9375vw; //18px;
        width: 35vw; //690px;

        .step-text {
          font-size: 0.8333333333333334vw; //16px;
          margin-bottom: 0.8854166666666666vw; //17px;
        }
        .step-text2 {
          font-size: 1.25vw; //24px;
          margin-bottom: 2.9166666666666665vw; //56px;
        }
        .hash-tag {
          font-size: 0.8854166666666666vw; //17px;
          margin-bottom: 0.625vw; //12px;
        }
        .section1 {
          padding-left: 1.0416666666666665vw; //20px;
          padding-right: 1.0416666666666665vw; //20px;
          height: 31.197916666666664vw; //599px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .head-title {
            font-size: 4.166666666666666vw; //80px;
          }
          .step-text3 {
            font-size: 2.2916666666666665vw; //44px;
          }
          .separator-line {
            width: 1px;
            height: 11.041666666666666vw; //212px;
            background-color: white;
          }
          .step-group {
            display: flex;
            flex-basis: 49%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1.8229166666666667vw; //35px;

            .col {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .section2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .button-icon {
            width: 0.9895833333333333vw; //19px;
            height: 1.1458333333333333vw; //22px;
            margin-right: 0.55vw;
          }
          .jc {
            justify-content: center;
          }
          .row {
            display: flex;
            justify-content: center;
            align-items: center;

            .button-title {
              font-size: 0.8333333333333334vw; //16px;
            }
          }

          .mb14 {
            margin-bottom: 0.7291666666666666vw; //14px;
          }
        }
      }
    }
  }
  .share-popup {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3.125vw; //60px;

    .share-title {
      font-size: 4.166666666666666vw; //80px;
      margin-bottom: 2.083333333333333vw; //40px;
    }
    .row {
      display: flex;
    }

    .image-sticker {
      width: 100%;
      margin-top: 3.6979166666666665vw; //71px;
      .double-sticker {
        width: 9.739583333333334vw; //187px;
        height: 7.552083333333333vw; //145px;
        margin-left: 9.166666666666666vw; //176px;
      }
    }
  }
}

#iframe_scoreboard {
  position: relative;
  display: none;
  &.show {
    display: block;
  }
}
