.power-of-magnetic-popup {
  .power-of-magnetic {
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    justify-content: center;
    scrollbar-width: none;
    .healthy-beautiful-skin {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #898989;
      padding-bottom: 4.5%;
      .text-part {
        width: 36%;
        text-align: center;
        .head-title {
          font-size: 4.167vw;
          margin-bottom: 8%;
          margin-top: 2%;
        }
        .normal-text {
          font-size: 1.04vw;
          .bold-text {
            font-style: italic;
            font-size: 1.04vw;
          }
        }
      }
      .img-part {
        width: 64%;
        img {
          width: 100%;
        }
      }
    }

    .super-magnetic-acid {
      padding: 5%;
      border-bottom: 1px solid #898989;
      .super-magnetic-acid-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .head-title {
          font-size: 4.167vw;
          &.super-magnetic {
            font-size: 4.94791vw;
          }
        }
        .powered-by {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          span {
            margin-right: 2%;
          }
          img {
            width: 4.583vw;
          }
        }

        .normal-text {
          font-size: 1.458vw;
          margin: 3% 0;
          font-family: 'HelveticaNeueLTStd-Cn';
          span.bold-text {
            font-family: 'HelveticaNeueLTStd-BdCn';
            font-size: 1.458vw;
          }
        }
      }

      .super-magnetic-acid-img {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 3%;
        img {
          width: 32%;
        }
      }
    }

    .gentle-cleansing {
      padding: 5%;
      border-bottom: 1px solid #898989;
      .gentle-cleansing-header {
        text-align: center;
        margin-bottom: 7%;
        .head-title {
          font-size: 4.167vw;
          sup {
            font-size: 1vw;
            font-family: 'HelveticaNeueLTStd-Bold';
          }
        }
      }
      .gentle-cleansing-chart-group {
        display: flex;
        justify-content: space-between;
        .gentle-cleansing-chart {
          width: 48%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .reduction-info {
            width: 11.979vw;
            height: 11.979vw;
            border-radius: 50%;
            border: 1px solid #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            .head-title {
              font-size: 4.6875vw;
              line-height: 1;
            }
            .bold-text {
              font-size: 1.1458vw;
              margin-bottom: 10%;
              font-family: 'HelveticaNeueLTStd-BdCn';
            }
          }
          .chart-title {
            font-size: 1.5667vw;
            margin-top: 7%;
            margin-bottom: 3%;
            font-family: 'HelveticaNeueLTStd-BdCn';
          }
          .chart-img {
            margin-top: 5%;
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
      }
    }

    .full-range {
      padding: 5%;
      border-bottom: 1px solid #898989;
      .full-range-header {
        text-align: center;
        margin-bottom: 5%;
        .head-title {
          margin-top: 2%;
          font-size: 4.1667vw;
          margin-bottom: 3%;
        }
        .bold-text {
          &.created-to {
            font-size: 1.04167vw;
          }
        }
      }
      .reactive-skin {
        padding: 5%;
        padding-top: 1.5%;
        &.dry {
          background-color: #0072ce;
        }
        &.oily {
          background-color: #ff6900;
        }
        .reactive-skin-title {
          margin-top: 5%;
          display: flex;
          justify-content: center;
          margin-bottom: 3%;
          .head-title {
            margin-top: -4%;
            font-size: 4.1667vw;
          }
        }
        .reactive-skin-img {
          display: flex;
          justify-content: space-between;
          img {
            width: 47%;
          }
        }
      }
    }

    .note-section {
      padding: 5%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      .note-text {
        width: 98%;
        color: #d3d3d3; // #555555;
        font-size: 0.625vw;
        margin-bottom: 10%;
        font-family: 'HelveticaNeueLTStd-Cn';
        sup {
          font-size: 0.4vw;
        }
        @media screen and (max-width: 700px) {
          font-size: 6px;
          width: 100%;
          sup {
            font-size: 4px;
          }
        }
      }
    }
  }
}
