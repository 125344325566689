@font-face {
  font-family: 'HelveticaNeueLTStd-Bold';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/HelveticaNeueLTStd-Bold.ttf');
}
@font-face {
  font-family: 'HelveticaNeueLTStd-Roman';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/HelveticaNeueLTStd-Roman.ttf');
}
@font-face {
  font-family: 'HelveticaNeueLTStd-Cn';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/HelveticaNeueLTStd-Cn.otf');
}
@font-face {
  font-family: 'HelveticaNeueLTStd';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/HelveticaNeueLTStd-Lt.otf');
}
@font-face {
  font-family: 'HelveticaNeueLTStd-LtCn-57';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/HelveticaNeueLTStd-LtCn.otf');
}

@font-face {
  font-family: 'HelveticaNeueLTStd-BdCn';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/HelveticaNeueLTStd-BdCn.ttf');
}

@font-face {
  font-family: 'Serendipity';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Serendipity.otf');
}
@font-face {
  font-family: 'SerendipityWide';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Serendipity.ttf');
}
