.tv-demo-start {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .container {
    .header {
      width: 100%;
      margin-bottom: 1.8229166666666667vw; //35px;
      .head-title {
        font-size: 4.166666666666666vw; //80px;
        margin-bottom: -0.5208333333333333vw; //-10px;
        text-align: center;
      }
      .bold-text {
        text-align: center;
      }
    }
    .btn-group {
      margin-top: 8.229166666666666vw; //158px;
      .row {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2.083333333333333vw; //40px;

        .ml50 {
          margin-left: 2.604166666666667vw; //50px;
        }

        .button {
          width: 28.125vw; //540px;
          height: 4.6875vw; //90px;
          border: 0.052083333333333336vw solid white; //1px
          display: flex;
          padding-left: 2.34375vw; //45px;
          padding-right: 2.34375vw; //45px;
          justify-content: space-between;
          .img-icon {
            width: 3.6458333333333335vw; //70px;
            height: 4.53125vw; //87px;
            margin-right: 2.34375vw; ///45px;
          }
          .button-title {
            width: 100%;
            font-size: 1.458vw; //28px;
          }
        }
      }
    }
  }
}

.tv-magnetic-demo {
  .container {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 3.90625vw; //75px;
    .left-content {
      width: 40%;
      .header {
        width: 100%;
        .head-title {
          font-size: 3.4375000000000004vw; //66px;
          margin-bottom: 1.3020833333333335vw; //25px;
          // text-align: center;
          word-spacing: 0.5vw;
        }
        .normal-text {
          font-size: 1.25vw; //24px;
          max-width: 22vw; //425px;
        }
      }
    }
    .right-content {
      width: 60%;
      min-height: 23.854166666666668vw; //458px;
    }
  }
  .btn-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5.78125vw; //111px;
    .main-button {
      margin-right: 3%;
      width: 13.020833333333334vw; //250px;
      height: 2.083333333333333vw; //40px;
      border: 0.052083333333333336vw solid white; //1px
      display: flex;
      align-items: center;
      justify-content: space-between;
      .row {
        display: flex;
        align-items: center;
      }
      .button-title {
        font-size: 0.8333333333333334vw;
      }
      .img-icon {
        width: 0.9895833333333333vw; //19px;
        height: 1.1458333333333333vw; //22px;
        margin-right: 0.55vw;
      }
    }
  }
}

.tv-pierced-agar-demo {
  .container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 700px) {
      margin-top: 0.5vw;
    }
    .left-content {
      width: 32%;
      .header {
        width: 100%;
        margin-top: 4.791666666666667vw; //92px;
        .head-title {
          font-size: 3.4375000000000004vw; //66px;
          margin-bottom: 1.3020833333333335vw; //25px;
          // text-align: center;
          word-spacing: 0.5vw;
        }
        .normal-text {
          font-size: 1.0416666666666665vw; //20px;
          max-width: 21.75vw; //425px;
        }
      }
    }
    .right-content {
      width: 65%;
      // width: 42.34375vw; //813px;
      min-height: 23.854166666666668vw; //458px;

      .row {
        display: flex;
        .col {
          flex-basis: 50%;
          // padding-left: 2.083333333333333vw; //40px;
          // padding-right: 2.083333333333333vw; //40px;
          display: flex;
          flex-direction: column;
          // justify-content: center;
          align-items: center;
          .bold-text {
            font-size: 1.0416666666666665vw; //20px;
            text-align: center;
            text-transform: uppercase;
          }
          .normal-text {
            font-size: 0.7291666666666666vw; //14px;
            text-align: center;
            margin-top: 1.1458333333333333vw; //22px;
          }
          &.conventional_cleanser {
            img {
              width: 20.416666666666668vw; //392px;
              // height: 412px;
              margin-left: 1.8229166666666667vw; //35px;
            }
            .bold-text {
              margin-top: 1.7vw; //;
              font-family: 'HelveticaNeueLTStd-BdCn';
            }
            .normal-text {
              max-width: 17vw;
            }
          }
          &.neutrogena_labs_magnetic_gel {
            padding-left: 0.78125vw; //15px;
            padding-right: 0px;
            // padding-right: 0.4166666666666667vw; //8px;

            img {
              width: 22.916666666666664vw; //440px;
              margin-top: 2.083333333333333vw; //40px;
              margin-left: -1.3020833333333335vw; //-25px;
              margin-right: 4.427083333333334vw; //85px;
              // height: 412px;
            }
            .normal-text {
              max-width: 20.1vw;
            }
            .bold-text {
              font-family: 'HelveticaNeueLTStd-BdCn';
            }
          }
        }
      }
    }
  }
  .btn-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4.166666666666666vw; //80px;
    .main-button {
      margin-right: 3%;
      width: 13.020833333333334vw; //250px;
      height: 2.083333333333333vw; //40px;
      border: 0.052083333333333336vw solid white; //1px
      display: flex;
      align-items: center;
      justify-content: space-between;
      .row {
        display: flex;
        align-items: center;
      }
      .button-title {
        font-size: 0.8333333333333334vw;
      }
      .img-icon {
        width: 0.9895833333333333vw; //19px;
        height: 1.1458333333333333vw; //22px;
        margin-right: 0.55vw;
      }
    }
  }
}

.tv-foam-demo {
  .container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 2.5vw;
    @media screen and (max-width: 700px) {
      margin-top: 3.5vw;
    }
    .left-content {
      width: 33%;
      .header {
        width: 100%;
        margin-top: 3.5vw;
        .head-title {
          font-size: 3.4375000000000004vw; //66px;
          margin-bottom: 1.3020833333333335vw; //25px;
          // text-align: center;
          word-spacing: 0.5vw;
        }
        .normal-text {
          font-size: 1.0416666666666665vw; //20px;
          max-width: 21.75vw; //425px;
        }
      }
    }
    .right-content {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      width: 65%;
      min-height: 23.854166666666668vw; //458px;

      img {
        width: 42.34375vw; //813px;
        // height: 412px;
      }
      .row {
        display: flex;
        width: 100%;
        margin-top: 0.8vw;
        .col {
          flex-basis: 50%;
          // padding-left: 2.083333333333333vw; //40px;
          padding-right: 2.083333333333333vw; //40px;
          .bold-text {
            font-size: 1.0416666666666665vw; //20px;
            text-align: center;
            text-transform: uppercase;
          }
          .normal-text {
            font-size: 0.7291666666666666vw; //14px;
            text-align: center;
            margin-top: 1.1458333333333333vw; //22px;
          }
          &.conventional_cleanser {
            .bold-text {
              font-family: 'HelveticaNeueLTStd-BdCn';
            }
          }
          &.neutrogena_labs_magnetic_gel {
            padding-left: 1vw; //18px;
            padding-right: 0.4166666666666667vw; //8px;
            .bold-text {
              font-family: 'HelveticaNeueLTStd-BdCn';
            }
          }
        }
      }
    }
  }
  .btn-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4.166666666666666vw; //80px;
    .main-button {
      margin-right: 3%;
      width: 13.020833333333334vw; //250px;
      height: 2.083333333333333vw; //40px;
      border: 0.052083333333333336vw solid white; //1px
      display: flex;
      align-items: center;
      justify-content: space-between;
      .row {
        display: flex;
        align-items: center;
      }
      .button-title {
        font-size: 0.8333333333333334vw;
      }
      .img-icon {
        width: 0.9895833333333333vw; //19px;
        height: 1.1458333333333333vw; //22px;
        margin-right: 0.55vw;
      }
    }
  }
}

.tv-ph-demo {
  .container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 4.166666666666666vw; //80px;
    margin-left: 0.5vw;
    .left-content {
      width: 29%;
      margin-right: 3vw;
      .header {
        width: 100%;
        // margin-top: -1vw;
        .head-title {
          font-size: 3.4375000000000004vw; //66px;
          margin-bottom: 1.3020833333333335vw; //25px;
          // text-align: center;
          word-spacing: 0.5vw;
          //
          span {
            font-family: 'SerendipityWide';
          }
        }
        .normal-text {
          font-size: 1.0416666666666665vw; //20px;
          max-width: 27.5vw; //425px;
        }
      }
    }
    .right-content {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      width: 65%;
      min-height: 23.854166666666668vw; //458px;

      img {
        margin-top: 1vw;
        width: 42.34375vw; //813px;772
        // height: 412px;
      }
      .row {
        display: flex;
        width: 100%;
        margin-top: 0.8vw;
        padding-right: 0vw;
        .col {
          flex-basis: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // padding-left: 2.083333333333333vw; //40px;
          // padding-right: 2.083333333333333vw; //40px;
          .bold-text {
            font-size: 1.0416666666666665vw; //20px;
            text-align: center;
            text-transform: uppercase;
          }
          .normal-text {
            font-size: 0.7291666666666666vw; //14px;
            text-align: center;
            margin-top: 1.1458333333333333vw; //22px;
          }
          &.conventional_cleanser {
            padding-right: 1vw;
            .normal-text {
              max-width: 18vw;
            }
            .bold-text {
              font-family: 'HelveticaNeueLTStd-BdCn';
            }
          }
          &.neutrogena_labs_magnetic_gel {
            padding-left: 0.5vw;
            // padding-left: 1vw; //18px;
            // padding-right: 0.4166666666666667vw; //8px;
            .normal-text {
              max-width: 18vw;
            }
            .bold-text {
              font-family: 'HelveticaNeueLTStd-BdCn';
            }
          }
        }
      }
    }
  }
  .btn-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4.166666666666666vw; //80px;
    .main-button {
      margin-right: 3%;
      width: 13.020833333333334vw; //250px;
      height: 2.083333333333333vw; //40px;
      border: 0.052083333333333336vw solid white; //1px
      display: flex;
      align-items: center;
      justify-content: space-between;
      .row {
        display: flex;
        align-items: center;
      }
      .button-title {
        font-size: 0.8333333333333334vw;
      }
      .img-icon {
        width: 0.9895833333333333vw; //19px;
        height: 1.1458333333333333vw; //22px;
        margin-right: 0.55vw;
      }
    }
  }
}
