.head-title {
  font-family: 'Serendipity';
  // font-size: calc(9.3vw + 1em);
  font-size: 7.8125vw; //150px/1920px
  line-height: 1.3;
  color: white;
}
.bold-text {
  font-family: 'HelveticaNeueLTStd-Bold';
  font-size: 1.5625vw; //30px/1920px
  color: white;
}
.normal-text {
  font-family: 'HelveticaNeueLTStd-Roman';
  font-size: 1.458vw; //28px/1920px
  color: white;
}

.blue-button {
  position: absolute;
  background-image: url('./images/blue_icon_animate.gif');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  cursor: pointer;
  width: 5.677083333333333vw; //109px;
  height: 11.354166666666666vw; //5.677083333333333vw; //109px;
  margin-top: -2.864583333333333vw; //-55px;
  // :active {
  //   opacity: 0.2;
  // }
}
.orange-button {
  position: absolute;
  background-image: url('./images/orange_icon_animate.gif');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  cursor: pointer;
  width: 5.677083333333333vw; //109px;
  height: 11.354166666666666vw; //5.677083333333333vw; //109px;
  margin-top: -2.864583333333333vw; //-55px;
}
.click-here-left {
  width: 5.729166666666666vw; //110px;
  height: 2.083333333333333vw; //40px;
  background-image: url('./images/left_click_box.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .bold-text {
    font-size: 0.8333333333333334vw; //16px;
    text-align: center;
  }
}
.click-here-right {
  width: 5.729166666666666vw; //110px;
  height: 2.083333333333333vw; //40px;
  background-image: url('./images/right_click_box.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .bold-text {
    font-size: 0.8333333333333334vw; //16px;
    text-align: center;
  }
}
.custom-input {
  width: 20.833333333333336vw; //400px;
  height: 2.083333333333333vw; //40px;
  background-color: transparent;
  border: 0.10416666666666667vw solid white; //2px
  color: white;
  padding: 0vw 0.5208333333333333vw; //10px;
  border-radius: 0;
}
.direction-group {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 0.7291666666666666vw; //14px;
    text-align: center;
    text-transform: uppercase;
    margin: 0.4166666666666667vw; //8px;
    margin-top: 1.3020833333333335vw; //25px;
    margin-bottom: 1.3020833333333335vw; //25px;
  }
  .next-page {
    width: 3.958333333333333vw; //76px;
    height: 2.1875vw; //42px;
    // width: 6.25vw; //120px;
    // height: 3.3854166666666665vw; //65px;
    cursor: pointer;
    background-image: url('./images/direction_arr_animate.gif');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    transform: rotate(180deg);
  }

  .pre-page {
    width: 3.958333333333333vw; //76px;
    height: 2.1875vw; //42px;
    // width: 6.25vw; //120px;
    // height: 3.3854166666666665vw; //65px;
    cursor: pointer;
    background-image: url('./images/direction_arr_animate.gif');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
  }
}
